/* -------------*/

/* redesign */

$screensize-xs: 568px;
$screensize-sm: 896px;
$screensize-lg: 1200px;
$purple: #4C338C;
$cyan: #96d7d2;
$cyan-light: rgba(150, 215, 210, 0.5);
$yellow: #F2C24E;
$pink: #eb3c96;
$red: #DA3759;
$darkred: #d10101;
$lightgray: #edeeea;
$darkgray: #A6A8A7;
$white: white;
$black: black;

.cs-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-self: center;
  width: 100%;
  max-width: 906px;
  padding: 0 20px;
}

.cs-main-container-width {
  width: 100%;
  align-self: center;
  max-width: 700px;
  margin-top: 20px;
}

.userguide-main-container-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cs-main-container-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.userguide-main-container {
  width: 100%;
}

.content-item-content {
  width: 100%;
  margin: 10px;
}

.main-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 906px;
  padding: 0 10px;
}

.content-item-content-size {
  max-width: 600px;
}

.content-item-icon {
  margin: 10px;
  flex: 50%;

  @media screen and (max-width: 730px) {
    display: none !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.blankPage strong {
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 10px;
}

html {
  overflow-y: auto !important;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.bulletpointlist {
  color: $purple;
  list-style: initial;
}

* {
  box-sizing: border-box;
}

.modal {
  justify-content: start !important;
}

.factorSelection_verification_headline {
  margin-top: 20px;
  font-weight: bold;
}

.successPreLogonContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 6px;
  padding: 5px 15px 15px 15px;

  strong {
    color: black !important;
  }

  p {
    color: var(--text-color) !important;
  }

}

.successPreLogonHeadline,
.infoHeadline {
  padding-left: 15px;
}

.success-detached {

  display: flex;
  justify-content: center;

  p {
    width: 70%;
    text-align: center;
    background: white;
    z-index: 1
  }

  .detached-arrow-img {
    width: 100px;
    height: 240px;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  .detached-arrow-icon {
    position: relative;
    display: inline-block;
    /* Text ausblenden */
    color: transparent;
   // font-size: 16px;
  }

  .detached-arrow-icon::before {
    content: "◄"; /* Hier das Icon oder Symbol */
    color: black; /* Farbe des Icons */
    left: 0;
    top: 0;
  }
}


/* Loading spinner Azure Auth */
@keyframes ProgressFlagAnimation {
  from {
    left: 0;
  }
  to {
    left: 350px;
  }
}

.ProgressFlag.Show {
  opacity: 1;
}

.ProgressFlag > div {
  width: 2px;
  height: 2px;
  margin: 0 5px;
  position: relative;
  -webkit-animation: ProgressFlagAnimation 1.45s infinite ease-in-out; /* Chrome, Safari, Opera */
  -webkit-animation-delay: -2s; /* Chrome, Safari, Opera */
  animation: ProgressFlagAnimation 5s infinite ease-in-out;
  animation-delay: -2s;
  text-align: center;
  display: inline-block;
}

.ProgressFlag > div:nth-child(1) {
  -webkit-animation-delay: 0s;
}

.ProgressFlag > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
}

.ProgressFlag > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
}

/* End Loading spinner Azure Auth */

@media screen and (min-width: 1408px) {
  /* Otherwise site content is not horizontal centered on larger screens */
  .container {
    max-width: 906px !important;
  }
}

.listStyleNumber {
  list-style-type: lower-number;
  padding-left: 20px;
}

.device-switch-gif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 500px) {
    margin: 0;
    width: 100%;
  }
}

.device-switch-wrapper {
  .qr-code {
    background: white;
    padding: 16px;
    float: left;
    overflow-wrap: break-word;
  }

  .device-switch-locked {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .device-switch-modal {
    z-index: 1000;
    top: 35%;
    max-width: 600px;

    .device-switch-modal--qr-code {
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .device-switch-modal--qr-content {
      margin-bottom: 15px;
    }
  }
}

.second-channel-wrapper {
  .second-channel-modal {
    z-index: 1000;
    max-width: 600px;
    box-shadow: none;
  }
  .second-channel-pin {
    justify-content: center;
    text-align: center;
  }
}

.otp-to-colleague-or-manager-step-hint {
  h4 {
    margin-bottom: -20px;
  }

  p {
    margin-top: 20px;
  }
}

.onboarding_success_button {
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: end;

  @media only screen and (max-width: $screensize-xs) {
    button {
      width: 100%;
    }
  }
}

.recovery-initiate-success_buttons {
  place-self: stretch;
}

.fido-registration-success_buttons, .recovery-success_buttons {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  margin-top: 40px;

  @media only screen and (max-width: $screensize-xs) {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.form-padding {
  padding: 10px 0;
}

.header-svg-pwreset {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.pw-reset-onboarding-info {
  ol {
    margin: 20px 0;
    list-style: none;
  }
}

.registration {
  margin-bottom: 25px;

  .device-options_more-options {
    margin-top: 25px !important;

    img:hover {
      cursor: pointer;
    }

    h3:hover {
      cursor: pointer;
    }
  }

  .checkbox-wrapper {
    margin: 15px 0;
  }
}

.details-table-collapsible {
  display: flex;
  flex-direction: column;
  font-family: Lato, sans-serif;
  color: $purple;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 906px;

  @media screen and (max-width: 906px) {
    padding: 10px 10px;
  }
}

.execute_fido_registration {
  h2 {
    margin-top: 50px;
  }

  margin-bottom: 25px;

  .device-card-heading {
    margin-top: 25px;
  }

  .device-card-wrapper {
    margin-top: 20px !important;
  }

  .fidoRegistration-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: end;
    gap: 20px;
    flex-direction: row;

    @media screen and (max-width: 480px) {
      flex-direction: column-reverse;
    }
  }

  .registration-information-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.userguide-help-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .qr-code {
    display: flex;
    align-items: center;
  }

  .userguide-help-modal-separator {
    display: flex;
    width: 100%;
    align-items: center;

    hr {
      width: 50%;
      margin: 0;
      border: 1px solid var(--separator-color);
    }

    p {
      width: 60px;
      margin: 0;
      text-align: center;
    }
  }
}

.add-another-hint {
  padding: 10px;
  background: white;
  border-radius: 6px;
}

.pointer {
  cursor: pointer;
}

.modal {
  .storybook-icon-button {
    flex-direction: row-reverse;

    img {
      transform: rotate(270deg) !important;

      &.active {
        transform: none !important;
      }
    }
  }
}

.device-switch-qr-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.registration-checkbox-description {
  padding-left: 27px;
  margin-top: -15px;
}

.registration-platformAuth-img {
  margin-top: 100px;
  width: 100%;

  img {
    width: 100%;
  }
}

.registration-platformAuth-errormessage {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background: white;
  border-radius: 6px;
  padding: 0 10px;
}

.registration-platformAuth-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;

  button {
    border: 1px solid $purple;
    border-radius: 6px;
    padding: 0 10px;
  }

  img {
    margin-left: 5px;
    transform: rotate(0deg) !important;
  }
}

.device-card-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.p-dont-wrap-username {
  b {
    white-space: nowrap;
  }
}

.padding-top-10 {
  padding-top: 10px;
}

.recovery-user-info {
  border: 3px solid $purple;
  padding: 20px;
}

.matching-number {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  text-align: center;
}

@media print {
  @page {
    size: 210mm 297mm;
    margin: 5mm 5mm 15mm 5mm !important;
  }
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    background-color: white !important;

    .print-container {

      color: black !important;
      h1, h2, h3, h4, h5, h6 {
        color: black;
      }
      p {
        color: black;
      }
      .recovery-user-info {
        border: 3px solid black;
        padding: 20px;
      }
    }
  }
}

