@use "../../../../assets/styles/_variables";

.snackbar {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 600;
  min-height: 40px;
  width: 504px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 19;
  justify-content: space-between;
  background: var(--snackbar-background-color);
  border-radius: var(--snackbar-border-radius);
  transition: 0.25s ease-in-out;
  box-shadow: var(--box-shadow);

  opacity: 0;

  &.open {
    opacity: 1;
  }

  @media screen and (max-width: variables.$screensize-xs) {
    width: calc(100% - 64px);
  }

  .message {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    cursor: pointer;
    width: initial;
  }
}
